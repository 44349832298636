'use client'

import { PropsWithChildren } from 'react'
import { shallowEqual } from '@xstate/react'
import { twMerge } from 'tailwind-merge'

import { ShowcaseContext } from './ShowcaseContext'

interface ShowcaseStageProps {
  className?: string
}

export function ShowcaseStage({ children, className }: PropsWithChildren<ShowcaseStageProps>) {
  const { active, dimmed, standalone } = ShowcaseContext.useSelector(
    ({ matches, context: { dimmed, standalone } }) => ({
      active: matches('Active'),
      dimmed,
      standalone,
    }),
    shallowEqual,
  )

  return (
    <div
      className={twMerge(
        'relative flex-1 md:origin-right md:transition-all md:duration-700 md:ease-in-out',
        standalone && 'max-h-screen',
        dimmed && 'md:blur-md md:brightness-50 md:-translate-x-2 md:-rotate-y-6 md:scale-75',
        className,
      )}
    >
      <div
        className={twMerge(
          'absolute inset-0 flex items-center justify-center md:transition-[left,background] md:duration-700 md:ease-in-out',
          active && !standalone && 'md:-left-full',
        )}
      >
        {children}
      </div>
    </div>
  )
}
