'use client'

import { useEffect, useState } from 'react'
import { shallowEqual } from '@xstate/react'
import Image from 'next/image'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

import defaultImage from '@/assets/placeholder.png'
import { NeighbourhoodSummaryEntry } from '@/data/getNeighbourhoods'
import { formatStringList } from '@/utils/strings'
import { ActionLink } from './ActionLink'
import { ShowcaseContext } from './Showcase/ShowcaseContext'

interface NeighbourhoodSlidesProps {
  neighbourhoods: NeighbourhoodSummaryEntry[]
}

export function NeighbourhoodSlides({ neighbourhoods }: NeighbourhoodSlidesProps) {
  const [grabbing, setGrabbing] = useState(false)
  const { active, api } = ShowcaseContext.useSelector(
    ({ matches, context: { standalone, api } }) => ({
      active: matches('Active'),
      standalone,
      api,
    }),
    shallowEqual,
  )
  const index = ShowcaseContext.useSelector((state) => state.context.index)

  const [, sendToShowcase] = ShowcaseContext.useActor()

  useEffect(() => {
    if (api) {
      const handleGrab = () => setGrabbing(true)
      const handleRelease = () => setGrabbing(false)
      api.on('pointerDown', handleGrab)
      api.on('pointerUp', handleRelease)

      return () => {
        api.off('pointerDown', handleGrab)
        api.off('pointerUp', handleRelease)
      }
    }
  }, [api])

  return (
    <>
      {neighbourhoods.map(({ image, description, suburbs, uri }, i) => {
        const current = i === index
        const handleClick = () => sendToShowcase({ type: 'Go to slide', index: i })

        const src = image[0]?.url
        const blur = src ? image[0]?.blur ?? undefined : defaultImage.blurDataURL

        return (
          <div
            className={twMerge(
              'relative bottom-0 top-0 flex h-full w-[74vmin] shrink-0 select-none items-stretch justify-center @container md:duration-700',
              grabbing ? 'cursor-grabbing' : 'cursor-grab',
              current ? 'z-10' : 'scale-[0.75]',
            )}
            key={i}
            onClick={handleClick}
          >
            <div
              className={twMerge(
                'absolute -inset-x-[18vmin] inset-y-0 shadow-2xl duration-700 ease-in-out md:ease-in-out',
                current ? 'brightness-100 md:blur-0' : 'cursor-pointer brightness-50 md:blur safari:md:blur-0',
              )}
            >
              <Image
                src={image[0]?.url ?? defaultImage.src}
                blurDataURL={blur}
                placeholder={blur ? 'blur' : 'empty'}
                loading="lazy"
                fill
                className={twMerge('object-cover shadow-2xl')}
                alt="alt"
                sizes="(max-width: 768px) 100vw, 100vmin"
              />
              <div
                className={twMerge(
                  'absolute inset-x-8 inset-y-16 flex flex-col items-start justify-around text-sm font-semibold uppercase tracking-widest text-white opacity-0 drop-shadow-elevate ease-out xs:inset-x-8 xs:inset-y-20 xs:text-base sm:inset-x-12 sm:inset-y-1/6 sm:text-lg md:top-1/12 md:text-xl',
                  current ? 'pointer-events-auto' : 'pointer-events-none',
                  active ? 'duration-1000' : 'duration-150',
                  active && current && 'opacity-100',
                )}
              >
                <Link href={`/${uri}`} className="flex flex-col gap-y-4 md:gap-y-10">
                  <span className="font-display">Find your lifestyle</span>
                  <span className="whitespace-pre font-display text-2xl @xs:text-3xl @sm:text-4xl @md:text-4.5xl @md:leading-none @lg:text-5xl @2xl:text-6xl @4xl:text-8xl">
                    {description}
                  </span>
                  <span className="w-1/4 whitespace-pre font-sans">
                    {formatStringList(
                      suburbs.map(({ title }) => title),
                      { sort: false, and: ' & ' },
                    )}
                  </span>
                </Link>

                <div className="relative flex flex-1 items-end justify-start portrait:bottom-12 sm:portrait:bottom-0">
                  <ActionLink href={`/${uri}`} underline>
                    Explore
                  </ActionLink>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
